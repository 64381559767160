import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/398110536"
    notesSrc="https://www.docdroid.net/EFMkT9R/bible-group-homework-3-1415.pdf"
    notesText="Bible Group Homework"
  >
    <SEO title="Finding Courage in an Anxious World - Fear Not" />
  </Layout>
)

export default SermonPost
